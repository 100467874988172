<template>
  <v-dialog v-model="dialogEdit" max-width="590px">
    <div class="confirmWraper confirmWrapperBig">
      <div class="mx-auto my-5">
        <div class="confirmTitle">Редактор данных клиента</div>
        <div class="dialogClose" @click="openDelete">
          <v-icon> mdi-delete-forever </v-icon>
        </div>
        <DialogDeleteClient
          :openDeleteDialog="dialogDelete"
          :deletingClient="editingClientItem"
          @close-delete="dialogDelete = false"
        />
        <v-card-text class="editForm">
          <v-form class="px-3" ref="form" lazy-validation v-model="valid">
            <v-row>
              <v-col :cols="4">
                <div>ID клиента</div>
              </v-col>
              <v-col :cols="8">
                {{ editingClientItem.clientPassId || "ID" }}
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="4">
                <div>Имя клиента</div>
              </v-col>
              <v-col :cols="8">
                <div class="d-flex align-center">
                  <v-autocomplete
                    v-model="select"
                    :items="GET_CLIENTS"
                    label="Выберите клиента"
                    item-text="name"
                    item-value="id"
                    return-object
                    clearable
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Клиент не найден
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-btn
                            text
                            href="https://client.entrance.gomobile.ru/admin"
                          >
                            Создать
                          </v-btn>
                        </v-list-item-subtitle>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="reload"
                      >
                        <v-icon>mdi-reload</v-icon>
                      </v-btn>
                    </template>
                    <span>Обновить список</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
            <CreateGoogleClientHelp />
            <v-row>
              <v-col :cols="4">
                <div>Тип клиента</div>
              </v-col>
              <v-col :cols="8">
                <v-radio-group v-model="editingClientItem.isDirect" mandatory>
                  <v-radio label="GoMobile" :value="true"></v-radio>
                  <v-radio label="Watermark" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="4">
                <p>Почта админа</p>
              </v-col>
              <v-col :cols="8">
                <v-text-field
                  v-model="editingClientItem.admin"
                  :rules="adminRules"
                  :counter="50"
                  label="Эл. почта администратора"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </div>
      <div class="mx-auto my-5">
        <div class="confirmTitle">Google Drive клиента</div>
        <CreateGoogleDriveHelp />
        <v-card-text>
          <v-row justify="center">
            <p>{{ editingClientItem.gdriveFolderName }}</p>
            <v-col cols="11">
                <!-- v-model="editingClientItem.gdriveFolderId" -->
              <v-text-field
                :counter="150"
                label="Ссылка"
                :rules="urlRules"
                v-model="urlField"
              ></v-text-field>
            </v-col>
            <v-col :cols="1">
              <v-btn icon @click="urlField = ''"
                ><v-icon>delete</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <div class="d-flex mx-auto confirmWrapperBig">
        <button class="btn" :disabled="!valid" @click="saveItem">
          Подтвердить
        </button>
        <button class="btn btn2" @click="dialogEdit = false">Закрыть</button>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CreateGoogleDriveHelp from "./CreateGoogleDriveHelp";
import CreateGoogleClientHelp from "./CreateGoogleClientHelp";
import DialogDeleteClient from "./DialogDeleteClient";
export default {
  props: ["openEditDialog", "editingClientItem"],
  components: {
    CreateGoogleDriveHelp,
    CreateGoogleClientHelp,
    DialogDeleteClient,
  },
  data: () => ({
    dialogDelete: false,
    id: "",
    select: {},
    valid: false,
    name: "",
    deletingClient: "",
    adminRules: [
      (v) => !!v || "Обязательно поле!",
      (v) => v.length >= 7 || "Минимальная длина 7 символов!",
      (v) => /.+@.+\..+/.test(v) || "Почта должен иметь вид _____@__.__",
    ],
    urlRules: [
      (v) => v.length >= 7 || "Минимальная длина 7 символов!",
      (v) => /^[a-zA-Z0-9_.-]*$/.test(v) || "Только латиница и цифры",
    ],
    urlField:"",
    dialogEdit: false,
  }),
  created() {
    this.initialize();
  },
  watch: {
    openEditDialog(val) {
      if (val) {
        this.dialogEdit = true;
      }
    },
    dialogEdit(val) {
      if (!val) {
        this.$emit("close-edit");
      }
    },
    editingClientItem(val) {
      this.select = this.GET_CLIENTS.filter((el) => {
        return el.id == val.clientPassId;
      })[0];
    },
    select(val) {
      if (val === undefined || Object.keys(val).length === 0) {
        this.valid = false;
      } else {
        this.valid = true;
      }
    },
    urlField(val){
      if(val.includes('https://drive.google.com')){
        let  str = val.split('/')
          this.urlField = str[str.length-1]
      }
    }
  },
  computed: {
    ...mapGetters(["GET_CLIENTS"]),
  },
  methods: {
    ...mapActions(["CHANGE_DATA_CLIENT","LOAD_CLIENTS"]),
    initialize() {
      // this.select = this.GET_CLIENTS.filter(el => {
      //     return el.id == this.editingClientItem.clientPassId;
      // })[0];
      // selectedClient(){
      // return this.GET_CLIENTS.filter(el => {
      //     return el.id == this.editingClientItem.clientPassId;
      // })[0];
    },
    saveItem() {
      this.$refs.form.validate();
      let dataUpdate = {
        name: this.select.name,
        admin: this.editingClientItem.admin,
        gdrive_folder_id: this.urlField,
        client_pass_id: this.editingClientItem.clientPassId,
        is_direct: this.editingClientItem.isDirect,
      };
      this.CHANGE_DATA_CLIENT({
        id: this.editingClientItem.id,
        update: dataUpdate,
      }).then((el) => {
        if (el == "OK") {
          this.dialogEdit = false;
        }
      });
    },
    openDelete(item) {
      this.dialogDelete = true;
      this.deletingClient = item;
    }, reload() {
      this.LOAD_CLIENTS();
    },
  },
};
</script>
