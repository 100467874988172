var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tableWrapper"},[_c('router-link',{staticClass:"homeLink homeRoute",attrs:{"to":"/","tag":"a"}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")]),_c('span',{staticClass:"linkText"},[_vm._v("Страница клиента")])],1),(_vm.isLogined)?_c('div',[(_vm.isLoading)?_c('div',{staticClass:"loaderImg"},[_c('div',{staticClass:"loader"})]):_c('div',[_c('div',{staticClass:"mainTitle"},[_vm._v("Список наших клиентов")]),(_vm.GET_LOADING_USER)?_c('div',{staticClass:"loaderImg"},[_c('div',{staticClass:"loader"})]):_c('v-data-table',{staticClass:"mt-4 dataTable",attrs:{"headers":_vm.headers,"items":_vm.GET_USERS,"search":_vm.search,"sort-by":"id","fixed-header":"","height":"550","disable-pagination":true,"hide-default-footer":"","align-center":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableNav"},[_c('div',{staticClass:"navList"},[_c('span',{staticClass:"navTitle"},[_vm._v("Найти:")]),_c('label',{staticClass:"inputField",attrs:{"for":"find"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"searchField",attrs:{"type":"text","name":"find","placeholder":"Ivanov..."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_c('v-icon',[_vm._v(" mdi-magnify ")])],1),_c('div',{staticClass:"btnAbsAdmin"},[_c('div',{staticClass:"absInfo"},[_c('Info')],1),_c('div',{staticClass:"absCreate"},[_c('CreateGoogleUser')],1)])])])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold nameClientTable"},[_vm._v(_vm._s(_vm.getUserName(item) || "Отсутвует")+" ")])]}},{key:"item.isDirect",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isDirect ? "GO" : "WATERMARK")+" ")]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [(item.gdriveFolderId)?_c('a',{attrs:{"href":_vm.urlGoogle + item.gdriveFolderId,"target":"_blank"}},[_vm._v(_vm._s(item.gdriveFolderName || item.gdriveFolderId))]):_c('span',[_vm._v("Добавлю позже")])]}},{key:"item.activation",fn:function(ref){
var item = ref.item;
return [(item.gdriveFolderId)?_c('div',[_c('ActivateCreative',{staticClass:"activateCollumn",attrs:{"item":item}})],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"icon",attrs:{"small":""},on:{"click":function($event){return _vm.editClient(item)}}},[_vm._v(" mdi-lead-pencil ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"icon",attrs:{"small":""},on:{"click":function($event){return _vm.openDelete(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1),_c('Info'),_c('DialogEditClient',{attrs:{"openEditDialog":_vm.dialogEdit,"editingClientItem":_vm.actionClient},on:{"close-edit":function($event){_vm.dialogEdit = false}}}),_c('DialogDeleteClient',{attrs:{"openDeleteDialog":_vm.dialogDelete,"deletingClient":_vm.actionClient},on:{"close-delete":_vm.closeDelete}})],1):_c('div',{staticClass:"welcomPage"},[_c('WelcomeUserPage',{attrs:{"itemData":_vm.itemData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }