<template>
  <div class="toggleWrapper">
    <div @click="toggle = !toggle" class="toggleBtn">
      <v-icon class="toggleText"> mdi-help-circle-outline </v-icon>
      <span class="toggleText mx-1"> Нет нужного клиента? </span>
    </div>
    <div v-if="toggle">
      <div class="toggleText">Скорее всего вы его еще не создали в:</div>
      <div class="toggleText">
        <a target="_blank" href="https://client.entrance.gomobile.ru/admin"
          >client.entrance.gomobile.ru/admin</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggle: false,
    };
  },
};
</script>
