<template>
  <v-dialog v-model="dialog" max-width="434px">
    <template v-slot:activator="{ on, attrs }">
      <button class="infoBtn" v-bind="attrs" v-on="on">
         <v-icon> mdi-information </v-icon>
      </button>
    </template>
    <div class="confirmWraper">
      <div class="dialogClose" @click="dialog = false">
        <v-icon> mdi-close </v-icon>
      </div>
      <div class="modal-container">
        <div class="infoTitle">
          Добро пожаловать в интерфейс управления клиентскими креативами!
        </div>
        <p>
          Чтобы добавить нового клиента нажмите большую кнопку вверху страницы.
        </p>
        <p>
          Для всех действий с настройками клиента зайдите на его страницу, нажав
          на иконку редактирования (с карандашом)
        </p>
        <p>
          Если вы забыли на какую страницу надо зайти клиенту для апрува
          клиентов, то <a href="/">вот она</a>.
        </p>
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
  }),
};
</script>
<style lang="scss">
@import "../assets/css/info.scss";
</style>