<template>
  <div class="toggleWrapper">
    <div @click="toggle = !toggle" class="toggleBtn">
     <v-icon class="toggleText"> mdi-help-circle-outline </v-icon>
      <span class="toggleText mx-1"> Помочь? </span>
    </div>
    <div v-if="toggle">
      <div class="toggleText">*Всё что после:</div>
      <div class="toggleText">https://drive.google.com/drive/folders/</div>
      <div class="toggleText">Например:</div>
      <div class="toggleText">
        <strong>1ytbQilkbxS0RYLRVQv2BUj6YRYv0IXdJ</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggle: false,
    };
  },
};
</script>
