<template>
  <div class="activateWrapper" title="Ручной запуск обработки">
    <div class="activeMain" v-if="!isError">
      <div class="showMes" v-if="showMes">
        {{ message }}
      </div>
      <div v-else>
        <v-icon v-if="!isLoading" class="icon" @click="activeCreative()">
          arrow_circle_up
        </v-icon>
        <div v-else class="loadingActivate">
          <div class="loader"></div>
        </div>
      </div>
    </div>
    <div v-else class="errorIcon">
      <v-icon> mdi-alert-circle </v-icon>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      isLoading: false,
      isError: false,
      message: "",
      showMes:false
    };
  },
  methods: {
    ...mapActions(["ACTIVATE_CREATIVE"]),
    activeCreative() {
      this.isLoading = true;
      this.ACTIVATE_CREATIVE(this.item.id).then((el) => {
        if (el.status == true) {
          this.message = el.data;
          this.showMes = true
          this.isLoading = false;
          setTimeout(() => {
              this.showMes = false;
            }, 3000);
        } else {
          if (el.status == false) {
            this.message = el.data;
            this.isLoading = false;
            this.isError = true;
            setTimeout(() => {
              this.isError = false;
            }, 4000);
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
.activateWrapper {
  transition: all 0.5s;
  margin: 0 auto;
  .showMes{
      color: rgb(12, 145, 63);
      text-transform: uppercase;
  }
}
.loadingActivate {
  position: relative;
  .loader {
    width: 30px;
    height: 30px;
    &:after {
      content: " ";
      display: block;
      width: 24px;
      height: 24px;
      margin: 5px;
      border-radius: 50%;
      border: 2px solid #fff;
      border-color: #d1cee2 transparent #d1cee2 transparent;
      animation: spin 1.2s linear infinite;
    }
  }
}
.activeMain {
  .icon {
    color: #ff7323;
    transition: 0.5s;
    &:hover {
      opacity: 0.4;
    }
  }
}
.errorIcon {
  color: red;
}
</style>