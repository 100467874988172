<template>
  <v-dialog v-model="dialogDelete" max-width="434px">
    <div class="confirmWraper">
      <div class="confirmTitle">
        Вы точно уверены что хотите удалить {{ name || "" }}?
      </div>
      <div class="confirmBtnList">
        <button class="btn" @click="deleteItemConfirm">Удалить</button>
        <button class="btn btn2" @click="dialogDelete = false">Отмена</button>
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  props: ["openDeleteDialog", "deletingClient"],
  data() {
    return {
      dialogDelete: false,
    };
  },
  watch: {
    openDeleteDialog(val) {
      if (val) {
        this.dialogDelete = true;
      }
    },
    dialogDelete(val) {
      if (!val) {
        this.$emit("close-delete");
      }
    },
  },
  computed: {
    name() {
      return this.deletingClient.name;
    },
  },
  methods: {
    deleteItemConfirm() {
      this.$store.dispatch("DELETE_USER", this.deletingClient.id).then(() => {
        this.dialogDelete = false;
      });
      this.$emit("close-delete");
    },
  },
};
</script>
