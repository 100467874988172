<template>
  <div class="creatingUser">
    <button class="addUser btn" @click="dialog = true">
      <span class="mobHide">Добавить</span>
      <v-icon> mdi-plus </v-icon>
    </button>
    <v-dialog v-model="dialog" max-width="434px">
      <div class="confirmWraper">
        <div class="dialogClose" @click="close">
          <v-icon> mdi-close </v-icon>
        </div>
        <div class="confirmTitle">Добавление клиента</div>
        <div class="addClientDialog">
          <v-container>
            <p class="error" v-if="error.length">{{ error }}</p>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="d-flex align-center">
                <v-autocomplete
                  v-model="select"
                  :items="GET_CLIENTS"
                  label="Выберите клиента"
                  item-text="name"
                  return-object
                  clearable
                  :rules="selectRules"
                  required
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title> Клиент не найден </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-btn
                          text
                          href="https://client.entrance.gomobile.ru/admin"
                        >
                          Создать
                        </v-btn>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="reload"
                    >
                      <v-icon>mdi-reload</v-icon>
                    </v-btn>
                  </template>
                  <span>Обновить список</span>
                </v-tooltip>
              </div>
              <CreateGoogleClientHelp />
              <v-radio-group v-model="radios" mandatory>
                <v-radio label="GoMobile" :value="true"></v-radio>
                <v-radio label="Watermark" :value="false"></v-radio>
              </v-radio-group>
              <v-text-field
                v-model="admin"
                :rules="adminRules"
                :counter="50"
                label="Почта сотрудника"
                required
              ></v-text-field>
              <div class="confirmTitle">Добавление Google Drive</div>
              <CreateGoogleDriveHelp />
              <v-text-field
                :counter="150"
                label="Ссылка"
                :rules="urlRules"
                v-model="urlField"
              ></v-text-field>
              <div class="confirmBtnList" v-if="select">
                <button
                  class="btn"
                  :disabled="!valid"
                  @click.prevent="createUser"
                >
                  Создать
                </button>
              </div>
            </v-form>
          </v-container>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CreateGoogleClientHelp from "./CreateGoogleClientHelp";
import CreateGoogleDriveHelp from "./CreateGoogleDriveHelp";

export default {
  components: {
    CreateGoogleDriveHelp,
    CreateGoogleClientHelp,
  },
  data: () => ({
    loading: true,
    radios: true,
    clients: [],
    select: null,
    admin: localStorage.getItem("profileMail") || "mail@mail.mail",
    gurl: "",
    dialog: false,
    valid: true,
    error: "",
    selectRules: [(v) => !!v || "Обязательно поле!"],
    adminRules: [
      (v) => !!v || "Обязательно поле!",
      (v) => v.length >= 7 || "Минимальная длина 7 символов!",
      (v) => /.+@.+\..+/.test(v) || "Почта должен иметь вид _____@__.__",
    ],
    urlRules: [
      (v) => v.length >= 7 || "Минимальная длина 7 символов!",
      (v) => /^[a-zA-Z0-9_.-]*$/.test(v) || "Только латиница и цифры",
    ],
    urlField: "",
  }),
  watch: {
    urlField(val) {
      if (val.includes("https://drive.google.com")) {
        let str = val.split("/");
        this.urlField = str[str.length - 1];
      }
    },
  },
  computed: {
    ...mapGetters(["GET_CLIENTS"]),
  },
  methods: {
    ...mapActions(["GET_USERS_FROM_API", "ADD_CLIENT", "LOAD_CLIENTS"]),
    createUser() {
      this.$refs.form.validate();
      this.ADD_CLIENT({
        name: this.select.name,
        admin: this.admin,
        gdrive_folder_id: this.urlField,
        client_pass_id: this.select.id,
        is_direct: this.radios,
      }).then((el) => {
        if (el.status == false) {
          this.error =
            "Данный Логин или Google Drive уже занят другим пользователем";
        } else {
          this.GET_USERS_FROM_API();
          this.close();
        }
      });
    },
    close() {
      this.dialog = false;
    },
    reload() {
      this.LOAD_CLIENTS();
    },
  },
};
</script>

<style lang="scss">
@import "../assets/css/createGoogleUser.scss";
</style>
