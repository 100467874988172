<template>
  <div class="tableWrapper">
    <router-link to="/" tag="a" class="homeLink homeRoute">
      <v-icon> mdi-chevron-left </v-icon>
      <span class="linkText">Страница клиента</span>
    </router-link>
    <div v-if="isLogined">
      <div v-if="isLoading" class="loaderImg">
        <div class="loader"></div>
      </div>
      <div v-else>
        <div class="mainTitle">Список наших клиентов</div>
        <div v-if="GET_LOADING_USER" class="loaderImg">
          <div class="loader"></div>
        </div>
        <v-data-table
          v-else
          :headers="headers"
          :items="GET_USERS"
          :search="search"
          sort-by="id"
          fixed-header
          height="550"
          :disable-pagination="true"
          hide-default-footer
          align-center
          class="mt-4 dataTable"
        >
          <template v-slot:top>
            <div class="tableNav">
              <div class="navList">
                <span class="navTitle">Найти:</span>
                <label for="find" class="inputField">
                  <input
                    class="searchField"
                    type="text"
                    name="find"
                    v-model="search"
                    placeholder="Ivanov..."
                  />
                  <v-icon> mdi-magnify </v-icon>
                </label>
                <div class="btnAbsAdmin">
                  <div class="absInfo">
                    <Info />
                  </div>
                  <div class="absCreate">
                    <CreateGoogleUser />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <span class="font-weight-bold nameClientTable"
              >{{ getUserName(item) || "Отсутвует" }}
            </span>
          </template>
          <template v-slot:[`item.isDirect`]="{ item }">
            {{ item.isDirect ? "GO" : "WATERMARK" }}
          </template>
          <template v-slot:[`item.link`]="{ item }">
            <a
              :href="urlGoogle + item.gdriveFolderId"
              target="_blank"
              v-if="item.gdriveFolderId"
              >{{ item.gdriveFolderName || item.gdriveFolderId }}</a
            >
            <span v-else>Добавлю позже</span>
          </template>
          <template v-slot:[`item.activation`]="{ item }">
            <div v-if="item.gdriveFolderId">
              <ActivateCreative :item="item" class="activateCollumn" />
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="icon" @click="editClient(item)">
              mdi-lead-pencil
            </v-icon>
          </template>

          <template v-slot:[`item.delete`]="{ item }">
            <v-icon small class="icon" @click="openDelete(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
      <Info />
      <DialogEditClient
        :openEditDialog="dialogEdit"
        :editingClientItem="actionClient"
        @close-edit="dialogEdit = false"
      />
      <DialogDeleteClient
        :openDeleteDialog="dialogDelete"
        :deletingClient="actionClient"
        @close-delete="closeDelete"
      />
    </div>
    <div v-else class="welcomPage">
      <WelcomeUserPage :itemData="itemData" />
    </div>
  </div>
</template>

<script>
import CreateGoogleUser from "@/components/CreateGoogleUser.vue";
import WelcomeUserPage from "@/components/WelcomeUserPage";
import Info from "@/components/Info";
import ActivateCreative from "@/components/ActivateCreative";
import DialogDeleteClient from "@/components/DialogDeleteClient";
import DialogEditClient from "@/components/DialogEditClient";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    CreateGoogleUser,
    WelcomeUserPage,
    Info,
    ActivateCreative,
    DialogDeleteClient,
    DialogEditClient,
  },
  data: () => ({
    isLoading: false,
    itemData: {
      welcometext: "в интерфейс управления клиентскими креативами!",
      description: ` Здесь вы сможете добавить вашего 
        клиента в Систему Креативов.
        Пожалуйста залогиньтесь, чтобы начать.`,
      isAdmin: true,
    },
    urlGoogle: "https://drive.google.com/drive/u/0/folders/",
    isLogined: false,
    headers: [
      /*  { text: "ID", align: "center", value: "id" }, */
      { text: "ID", align: "center", value: "clientPassId" },
      { text: "Имя", align: "center", value: "name" },
      { text: "Тип", align: "center", value: "isDirect" },
      { text: "Сотрудник", align: "center", value: "admin" },
      {
        text: " Google Drive",
        align: "center",
        value: "gdriveFolderId",
        sortable: false,
        value: "link",
      },
      { text: "Запуск", value: "activation", align: "center", sortable: false },
      { text: "Ред.", value: "actions", align: "center", sortable: false },
      { text: "Удалить", value: "delete", align: "center", sortable: false },
    ],
    search: "",
    dialogDelete: false,
    dialogEdit: false,
    actionClient: "",
  }),

  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters(["GET_USERS", "GET_CLIENTS", "GET_LOADING_USER"]),
  },
  methods: {
    ...mapActions([
      "GET_USERS_FROM_API",
      "LOAD_CLIENTS",
      "DELETE_USER",
      "GET_COOK",
    ]),
    initialize() {
     
      if (this.GET_CLIENTS.length == 0) {
        this.isLoading = true;
        this.LOAD_CLIENTS()
          .then((el) => {
            this.isLoading = el;
            return el;
          })
          .then((elem) => {
            if (elem == false) {
              this.GET_USERS_FROM_API().then((el) => {
                if (el == 401) {
                  this.isLogined = false;
                }
              });
            }
          });
      }
 if (
        window.location.hostname.includes("localhost") ||
        window.location.hostname.includes("192.168")
      ) {
        this.isLogined = true;
        console.log('yes');
        return
      }
      const cook = this.getCookie("auth");
      if (cook && cook != "false") {
        this.isLogined = true;
      }
    },

    getUserName(item) {
      if (Array.isArray(this.GET_CLIENTS)) {
        const filted = this.GET_CLIENTS.filter(
          (el) => el.id == item.clientPassId
        )[0];
        if (filted) {
          return filted.name;
        } else {
          return false;
        }
      }
    },

    editClient(item) {
      this.dialogEdit = true;
      this.actionClient = item;
    },
    openDelete(item) {
      this.dialogDelete = true;
      this.actionClient = item;
    },

    getCookie(name) {
      let matches = document.cookie.match(
        new RegExp(
          "(?:^|; )" +
            name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
            "=([^;]*)"
        )
      );
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    closeDelete(item) {
      this.dialogDelete = false;
      this.dialogEdit = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/css/adminlist.scss";
</style>
